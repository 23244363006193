import { defineComponent, ref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import { ZidAlert, ZidIcon, ZidLoader, ZidButton, ZidFileSelector } from '@zidsa/ui';
import { ThemeActionsEnum } from '@/domains/themes/types/management/themesManagement.type';
import { RoutingRouteEnum } from '@/router/routes.enum';
import VerticalBadgeIcon from '@/assets/icons/domains/themes/VerticalBadgeIcon.vue';
import RightPointingArrowIcon from '@/assets/icons/domains/team-members/RightPointingArrowIcon.vue';
import { ThemeFlowEnum } from '../../../types/management/themesManagement.type';
import { SideMenuTabsNamesEnum } from '@/domains/themes/types/management/theme-info-container/themeInfoContainer';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidAlert,
    ZidIcon,
    ZidLoader,
    ZidButton,
    ZidFileSelector,
    VerticalBadgeIcon,
    RightPointingArrowIcon,
  },
  props: ['isCreateFlowType', 'vThemeFile', 'isZipFileInvalid', 'isPreviousFileSaved'],
  emits: ['onThemeFileChange', 'onRemoveThemeFileClicked', 'onSideMenuTabChange'],
  setup(_, { emit }) {
    const isThemeUploading = ref(false);

    const isInitialZipFileLoading = ref(false);

    const onThemeFileChange = (file: File[]) => {
      emit('onThemeFileChange', file);
    };

    const onRemoveThemeFileClicked = () => {
      emit('onRemoveThemeFileClicked');
    };

    const onNextClicked = () => {
      emit('onSideMenuTabChange', SideMenuTabsNamesEnum.ThemeDetailsTab);
    };

    return {
      isThemeUploading,
      isInitialZipFileLoading,
      ThemeActionsEnum,
      RoutingRouteEnum,
      ThemeFlowEnum,
      onThemeFileChange,
      onRemoveThemeFileClicked,
      onNextClicked,
    };
  },
});
