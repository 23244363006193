export const isValidURL = (value: string) => {
  //eslint-disable-next-line
  return /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value);
};

export const isValidURLWithLocalhost = (value: string) => {
  //eslint-disable-next-line
  return /^(http(s)?:\/\/)(?:localhost|[\w.-]+(?:\.[\w\.-]+))+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value);
};

//eslint-disable-next-line
export const URL_REGEX: RegExp = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
