














































import { defineComponent } from '@vue/composition-api';
import { SideMenuTabsNamesEnum } from '@/domains/themes/types/management/theme-info-container/themeInfoContainer';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import AddFileIcon from '@/assets/icons/domains/themes/AddFileIcon.vue';
import EditFileIcon from '@/assets/icons/domains/themes/EditFileIcon.vue';
import EditImageIcon from '@/assets/icons/domains/themes/EditImageIcon.vue';
import PaintIcon from '@/assets/icons/domains/themes/PaintIcon.vue';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    AddFileIcon,
    EditFileIcon,
    EditImageIcon,
    PaintIcon,
  },
  props: ['sideMenuTabs'],
  emits: ['onSideMenuTabChange'],
  setup(_, ctx) {
    const onTabChange = (selectedTab: SideMenuTabsNamesEnum) => {
      ctx.emit('onSideMenuTabChange', selectedTab);
    };

    return {
      SideMenuTabsNamesEnum,
      onTabChange,
    };
  },
});
