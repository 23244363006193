import { ref } from '@vue/composition-api';
import { SubmitThemeSelectPropertyEnum } from '@/domains/themes/types/management/submit-theme/submitTheme.type';
import { TERMS_OF_USE, DEMO_STORE_URL_EXAMPLE, SAMPLE_FEATURE_IMAGE_OF_THEME } from '@/helpers/constantLinks';
import { ThemeActionsEnum } from '@/domains/themes/types/management/themesManagement.type';
import { ThemeDetailsInputPropertiesEnum } from '../../types/management/theme-info-container/themeInfoContainer';

const useThemeDetailsAndImages = () => {
  const DESCRIPTION_MAX_LENGTH = 280;

  const showPartnershipMessage = ref(false);

  const togglePartnershipMessage = () => (showPartnershipMessage.value = !showPartnershipMessage.value);

  return {
    TERMS_OF_USE,
    ThemeActionsEnum,
    DEMO_STORE_URL_EXAMPLE,
    SAMPLE_FEATURE_IMAGE_OF_THEME,
    DESCRIPTION_MAX_LENGTH,
    SubmitThemeSelectPropertyEnum,
    ThemeDetailsInputPropertiesEnum,
    showPartnershipMessage,
    togglePartnershipMessage,
  };
};

export default useThemeDetailsAndImages;
