import Vue from 'vue';
import { reactive, ref, onBeforeMount, computed } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import ErrorModel from '@/api/models/ErrorModel';
import router from '@/router';
import {
  SideMenuTabsInterface,
  SideMenuTabsNamesEnum,
  ThemeDetailsInputPropertiesEnum,
  ThemeInfoStateInterface,
  ThemeCategoryType,
  SingleIndustryType,
  ThemeDraftDataInterface,
  AdditionalFeature,
  StandardThemeFeatureItemInterface,
} from '@/domains/themes/types/management/theme-info-container/themeInfoContainer';
import {
  ItemStatusEnum,
  ItemUpdateStatusEnum,
  ThemeFlowEnum,
} from '@/domains/themes/types/management/themesManagement.type';
import { helpers, maxLength, minValue, required, requiredIf, email, decimal, minLength } from '@vuelidate/validators';
import {
  ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
  ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
} from '@/helpers/regex/text';
import { isValidURL } from '@/helpers/regex/URLs';
import { getFileFromURL, getImageFileFromURL } from '@/api/top-level-apis/helpers/fileFromURL';
import { getThemeCategories } from '@/domains/themes/api/management/themeCategories';
import { PublishingStatusLabelEnum } from '../../../types/management/themesManagement.type';
import {
  getStandardThemeFeatures,
  getThemeInformation,
  updateThemeInformation,
} from '@/domains/themes/api/management/themeInfo';
import { RoutingRouteEnum } from '@/router/routes.enum';
import ThemeDetailResponseModel from '@/domains/themes/models/management/ThemeDetailResponseModel';
import { submitThemeForReview } from '@/domains/themes/api/management/submitThemeForReview';
import I18nLocaleEnum from '@/helpers/i18n/i18nLocale.enum';

const useThemeInfoContainer = (themeId: string, flowType: ThemeFlowEnum) => {
  // Theme Details
  const DESCRIPTION_MAX_LENGTH = 280;
  // Theme Images
  const DESKTOP_SCREENSHOT_RATIO = 1.3;
  const DESKTOP_SCREENSHOT_MAX_WIDTH = 1600;
  const DESKTOP_SCREENSHOT_MIN_WIDTH = 800;
  const DESKTOP_SCREENSHOT_MAX_HEIGHT = 1200;
  const DESKTOP_SCREENSHOT_MIN_HEIGHT = 600;
  const MOBILE_SCREENSHOT_RATIO = 0.56;
  const MOBILE_SCREENSHOT_MAX_WIDTH = 720;
  const MOBILE_SCREENSHOT_MIN_WIDTH = 360;
  const MOBILE_SCREENSHOT_MAX_HEIGHT = 1280;
  const MOBILE_SCREENSHOT_MIN_HEIGHT = 640;
  const MAX_SCREENSHOTS_NUMBER = 9;
  const MIN_SCREENSHOTS_NUMBER = 3;

  // *** refs ***
  const isLoadingInformation = ref(false);
  const isLoadingPage = ref(false);
  const showPartnershipMessage = ref(false);
  const isCreateFlowType = ref(flowType == ThemeFlowEnum.IsCreateThemeFlow);
  const isPreviousFileSaved = ref(false);
  // Theme File
  const isZipFileInvalid = ref(false);
  // Theme Details
  const isDiscountEnabled = ref(false);
  // Theme Images
  const isFeaturedImageInvalid = ref(false);
  const areThemeImagesInvalid = ref(false);
  const isConfirmSubmitModalVisible = ref(false);
  const isLoadingSubmitTheme = ref(false);
  const hasSignedPartnershipForm = ref(true);
  const areInitialThemeImagesLoading = ref(false);
  const isInitialFeaturedImageLoading = ref(false);

  // *** reactives ***
  const themeInfoState = reactive<ThemeInfoStateInterface>({
    themeStatus: null,
    themeUpdateStatus: null,
    published_date: null,
    theme_version: '0.0.0',
    changes_notes_en: '',
    changes_notes_ar: '',
    // Theme File
    themeFile: null,
    // Theme Details
    themeDetails: {
      nameEn: '',
      nameAr: '',
      price: '',
      discountedPrice: '',
      category: [],
      previewUrl: '',
      demoStoreUrl: '',
      descriptionEn: '',
      descriptionAr: '',
      features: [],
      technicalSupportEmail: '',
      websiteLink: '',
    },
    // Theme Images
    themeImages: {
      images: [],
      featuredImage: null,
      acceptTerms: false,
    },
  });

  const originalThemeCategories = reactive<ThemeCategoryType>({
    options: [],
  });

  const preSelectedThemeCategories = reactive<Record<string, { id: number; name: string }[]>>({
    options: [],
  });

  const standardFeaturesList = reactive<Record<string, StandardThemeFeatureItemInterface[]>>({
    list: [],
  });

  const additionalFeaturesList = reactive<Record<string, AdditionalFeature[]>>({
    list: [
      {
        ar: '',
        en: '',
      },
    ],
  });

  const initialThemeImages = reactive({
    list: [
      ...themeInfoState.themeImages.images.map((screen: string | File) => {
        if (typeof screen === 'string') {
          return new File([], screen, {
            type: 'image/jpg',
          });
        } else return screen;
      }),
    ],
  });

  const originalThemeImages = reactive<Record<string, File[]>>({ list: [] });
  const initialThemeFeaturedImage = reactive<Record<string, File | null>>({ image: null });

  const sideMenuTabs = reactive<SideMenuTabsInterface>({
    themeFileTab: null,
    themeDetailsTab: false,
    themeImagesTab: false,
    updateThemeTab: null,
  });

  const rules = {
    themeFile: {
      themeFile: helpers.withMessage(
        'Please select a valid zip of maximum 5MB',
        requiredIf(() => isCreateFlowType.value),
      ),
      maxSize: helpers.withMessage('Please select a valid zip of maximum 5MB', (file: File) => {
        return !file || file.size <= 5000000;
      }),
      accepted: helpers.withMessage('Please select a valid zip file', (file: File) => {
        return !file || file.type === 'application/zip' || file.type === 'application/x-zip-compressed';
      }),
    },
    themeDetails: {
      nameEn: {},
      nameAr: {},
      price: {
        price: helpers.withMessage('Theme Price should be a valid number', required),
        decimal,
        minValue: minValue(1),
      },
      discountedPrice: {
        discountedPrice: helpers.withMessage(
          'Theme discounted price should be a valid number and not greater than the actual price',
          requiredIf(() => isDiscountEnabled.value),
        ),
        decimal,
        minValue: minValue(1),
        greaterThanPrice: helpers.withMessage(
          'Discounted price should be a greater then the price',
          (value: number) => {
            return value < Number(themeInfoState.themeDetails.price);
          },
        ),
      },
      category: {
        category: helpers.withMessage('Kindly select at least one category', required),
        maxCategory: helpers.withMessage('Max size is 5 categories', (value: []) => {
          return value.length < 6;
        }),
      },
      previewUrl: {
        required,
        previewUrl: helpers.withMessage('Please provide a valid Store URL', (value: string) => {
          return isValidURL(value);
        }),
      },
      demoStoreUrl: {
        required,
        demoStoreUrl: helpers.withMessage('Please provide a valid URL', (value: string) => {
          return isValidURL(value);
        }),
      },
      descriptionEn: {
        required,
        descriptionEn: helpers.withMessage(
          `Theme description is required in English & Arabic with maximum ${DESCRIPTION_MAX_LENGTH} characters`,
          (value: string) => {
            return ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(value);
          },
        ),
        maxLength: maxLength(DESCRIPTION_MAX_LENGTH),
      },
      descriptionAr: {
        required,
        descriptionAr: helpers.withMessage(
          `Theme description is required in English & Arabic with maximum ${DESCRIPTION_MAX_LENGTH} characters`,
          (value: string) => {
            return ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(value);
          },
        ),
        maxLength: maxLength(DESCRIPTION_MAX_LENGTH),
      },
      features: {
        required,
        minLength: minLength(1),
        $each: {
          required,
        },
      },
      featuresAr: {},
      technicalSupportEmail: {
        email,
        technicalSupportEmail: helpers.withMessage('Technical support E-mail is required', required),
      },
      websiteLink: {
        websiteLink: helpers.withMessage('Please provide a valid website Url', (value: string) => {
          return !value || isValidURL(value);
        }),
      },
    },
    themeImages: {
      images: {},
      featuredImage: {
        featuredImage: helpers.withMessage('Featured image is required', (value: File) => {
          return value.type.includes('image/');
        }),
      },
      acceptTerms: {
        required,
        acceptTerms: helpers.withMessage('You need to accepts the terms and conditions', (value: boolean) => value),
      },
    },
  };

  const v$ = useVuelidate(rules, themeInfoState);

  onBeforeMount(async () => {
    isLoadingPage.value = true;
    await getCategoriesList();
    isLoadingPage.value = false;
    await getThemeInfo();
    await getThemeFeatures();
    resetSideMenuState();
    hideIrrelevantSideMenuTabs();
    selectInitialSideMenuTab();
  });

  const getCategoriesList = async () => {
    const response = await getThemeCategories();

    if (response instanceof ErrorModel || !response.industries) {
      Vue.$toast.error('Failed to load Categories');
      router.back();
      return;
    }
    originalThemeCategories.options = [];
    const { industries } = response;
    if (industries.length > 0) {
      industries.forEach((category: SingleIndustryType) => {
        originalThemeCategories.options.push({
          id: category.id,
          name: category.name,
        });
      });
    } else Vue.$toast.error('Theme Categories Not Found');
  };

  const getThemeInfo = async () => {
    isLoadingInformation.value = true;

    const response = await getThemeInformation(themeId);

    if (response instanceof ErrorModel || !response.payload) {
      Vue.$toast.error('Unable to get theme information');
      router.push({ name: RoutingRouteEnum.ThemesManagement }).catch(() => {
        //
      });
      return;
    }

    if (
      response.payload.status == ItemStatusEnum.Pending ||
      response.payload.update_status == ItemUpdateStatusEnum.InReview
    ) {
      Vue.$toast.error('Unable view theme information');
      router.push({ name: RoutingRouteEnum.ThemesManagement }).catch(() => {
        //
      });
      return;
    }

    if (
      response.payload.status === ItemStatusEnum.Draft ||
      response.payload.status === ItemStatusEnum.Rejected ||
      response.payload.update_status === ItemUpdateStatusEnum.Rejected
    ) {
      isCreateFlowType.value = true;
    }

    themeInfoState.themeStatus = response.payload.status;
    themeInfoState.themeUpdateStatus = response.payload.update_status;
    themeInfoState.published_date = response.payload.published_date;
    themeInfoState.theme_version = response.payload.version;
    themeInfoState.changes_notes_ar = response.payload.changes_notes_ar;
    themeInfoState.changes_notes_en = response.payload.changes_notes_en;
    themeInfoState.themeDetails.nameEn = response.payload.theme_name || '';
    themeInfoState.themeDetails.nameAr = response.payload.theme_name_ar || '';
    themeInfoState.themeDetails.price = `${response.payload.price ?? ''}`;
    themeInfoState.themeDetails.discountedPrice = `${response.payload.discounted_price ?? ''}`;
    themeInfoState.themeDetails.previewUrl = response.payload.preview_url;
    themeInfoState.themeDetails.demoStoreUrl = response.payload.demo_url;
    themeInfoState.themeDetails.descriptionEn = response.payload.description;
    themeInfoState.themeDetails.descriptionAr = response.payload.description_ar;
    themeInfoState.themeDetails.features = [...response.payload.features] ?? [];
    additionalFeaturesList.list = response.payload.additional_features.length
      ? [...response.payload.additional_features]
      : [
          {
            ar: '',
            en: '',
          },
        ];
    themeInfoState.themeDetails.technicalSupportEmail = response.payload.technical_support_email;
    themeInfoState.themeDetails.websiteLink = response.payload.website_url;
    themeInfoState.themeImages.images = response.payload.images?.split(',') ?? [];
    isPreviousFileSaved.value = !!response.payload.file_path;

    isInitialFeaturedImageLoading.value = true;
    if (response.payload.featured_image) {
      const imageFile = await getImageFileFromURL(
        response.payload.featured_image,
        response.payload.featured_image,
        'image/jpg',
      );
      themeInfoState.themeImages.featuredImage = imageFile;
      initialThemeFeaturedImage.item = new File([], response.payload.featured_image, {
        type: 'image/jpg',
      });
    } else initialThemeFeaturedImage.item = null;

    isInitialFeaturedImageLoading.value = false;

    response.payload.category.forEach((categoryId) => {
      const category = originalThemeCategories.options.find((category) => category.id == categoryId);
      if (category) preSelectedThemeCategories.options.push(category);
    });

    hasSignedPartnershipForm.value = response.payload.has_signed_partnership_document || false;
    themeInfoState.themeDetails.category = [...preSelectedThemeCategories.options];

    isDiscountEnabled.value = Boolean(response.payload.discounted_price);

    if (themeInfoState.themeImages.images instanceof Array) {
      areInitialThemeImagesLoading.value = true;
      originalThemeImages.list = await getThemeImagesFiles();
      initialThemeImages.list = [
        ...themeInfoState.themeImages.images.map((screen: string | File) => {
          if (typeof screen === 'string') {
            return new File([], screen, {
              type: 'image/jpg',
            });
          } else return screen;
        }),
      ];
      areInitialThemeImagesLoading.value = false;
    } else {
      Vue.$toast.error('Unable view theme information');
      router.push({ name: RoutingRouteEnum.ThemesManagement }).catch(() => {
        //
      });
      isLoadingInformation.value = false;
      return;
    }

    isLoadingInformation.value = false;
  };

  const getThemeFeatures = async () => {
    const res = await getStandardThemeFeatures();
    if (res instanceof ErrorModel) {
      return;
    }
    res.features.forEach((feature) => {
      standardFeaturesList.list.push({
        id: feature.id,
        title: feature.title,
        checked: feature.required || themeInfoState.themeDetails.features.includes(feature.id),
        required: feature.required,
      });
      if (!themeInfoState.themeDetails.features.includes(feature.id) && feature.required)
        themeInfoState.themeDetails.features.push(feature.id);
    });
  };

  const resetSideMenuState = () => {
    sideMenuTabs.themeFileTab = false;
    sideMenuTabs.themeDetailsTab = false;
    sideMenuTabs.themeImagesTab = false;
    sideMenuTabs.updateThemeTab = false;
  };

  const hideIrrelevantSideMenuTabs = () => {
    if (
      isCreateFlowType.value ||
      themeInfoState.themeStatus == ItemStatusEnum.Draft ||
      themeInfoState.themeStatus == ItemStatusEnum.Rejected
    )
      sideMenuTabs.updateThemeTab = null;
    else sideMenuTabs.themeFileTab = null;
  };

  const selectInitialSideMenuTab = () => {
    if (
      isCreateFlowType.value ||
      themeInfoState.themeStatus == ItemStatusEnum.Draft ||
      themeInfoState.themeStatus == ItemStatusEnum.Rejected
    )
      sideMenuTabs.themeFileTab = true;
    else sideMenuTabs.themeDetailsTab = true;
  };

  const togglePartnershipModal = () => (showPartnershipMessage.value = !showPartnershipMessage.value);

  const onSideMenuTabChange = (clickedMenuTab: SideMenuTabsNamesEnum) => {
    resetSideMenuState();
    hideIrrelevantSideMenuTabs();
    sideMenuTabs[clickedMenuTab] = true;
  };

  // Theme File
  const onThemeFileChange = (file: File[]) => {
    if (!file) return;
    v$.value.themeFile.$touch();
    themeInfoState.themeFile = file[0];
    isZipFileInvalid.value = v$.value.themeFile.$error;
  };

  const onRemoveThemeFileClicked = () => {
    v$.value.themeFile.$touch();
    themeInfoState.themeFile = null;
    isZipFileInvalid.value = v$.value.themeFile.$error;
  };
  // /Theme File

  // Theme Details

  const onThemeDetailsInputChange = (dataProperty: ThemeDetailsInputPropertiesEnum, value: string) => {
    v$.value.themeDetails[dataProperty].$touch();
    themeInfoState.themeDetails[dataProperty] = value;
  };

  const onDiscountedPriceCheckboxClicked = (value: boolean) => {
    isDiscountEnabled.value = value;
    themeInfoState.themeDetails.discountedPrice = '';
  };

  const onSelectAllFeatures = (isChecked: boolean) => {
    if (isChecked) {
      standardFeaturesList.list.forEach((feature) => {
        feature.checked = true;
        if (!themeInfoState.themeDetails.features.includes(feature.id))
          themeInfoState.themeDetails.features.push(feature.id);
      });
    } else {
      standardFeaturesList.list.forEach((feature) => {
        if (!feature.required) feature.checked = false;
        if (themeInfoState.themeDetails.features.includes(feature.id) && !feature.required)
          themeInfoState.themeDetails.features.splice(
            themeInfoState.themeDetails.features.findIndex((fe) => fe === feature.id),
            1,
          );
      });
    }
  };

  const onSelectFeature = (value: boolean, featureID: number) => {
    if (value && !themeInfoState.themeDetails.features.includes(featureID))
      themeInfoState.themeDetails.features.push(featureID);

    if (!value && themeInfoState.themeDetails.features.includes(featureID))
      themeInfoState.themeDetails.features.splice(
        themeInfoState.themeDetails.features.findIndex((feature) => feature === featureID),
        1,
      );

    const index = standardFeaturesList.list.findIndex((feature) => feature.id === featureID);
    standardFeaturesList.list[index].checked = value;
  };

  const onAddAdditionalFeature = () => {
    additionalFeaturesList.list.push({
      ar: '',
      en: '',
    });
  };

  const onUpdateAdditionalFeatureInput = (value: string, index: number, locale: I18nLocaleEnum) => {
    additionalFeaturesList.list[index][locale] = value;
  };

  const onDeleteAdditionalFeature = (index: number) => {
    additionalFeaturesList.list.splice(index, 1);
  };

  const onSaveNewThemeDetailsChanges = async () => {
    const themeDetails: ThemeDraftDataInterface = {
      price: themeInfoState.themeDetails.price,
      discounted_price: themeInfoState.themeDetails.discountedPrice || '0',
      preview_url: themeInfoState.themeDetails.previewUrl,
      demo_url: themeInfoState.themeDetails.demoStoreUrl,
      description_en: themeInfoState.themeDetails.descriptionEn,
      description_ar: themeInfoState.themeDetails.descriptionAr,
      features: themeInfoState.themeDetails.features,
      additional_features: additionalFeaturesList.list,
      technical_support_email: themeInfoState.themeDetails.technicalSupportEmail,
      website_url: themeInfoState.themeDetails.websiteLink,
      featured_image:
        themeInfoState.themeImages.featuredImage instanceof File ? themeInfoState.themeImages.featuredImage : null,
      category:
        themeInfoState.themeDetails.category
          ?.map((c) => {
            return c.id;
          })
          .join(',') ?? '',
      images: [...originalThemeImages.list],
      theme_file: themeInfoState.themeFile instanceof File ? themeInfoState.themeFile : null,
    };

    const response = await updateThemeInformation(themeId, themeDetails);

    if (response instanceof ErrorModel || !response.payload) {
      Vue.$toast.error('Could not save information');
      return;
    }
    Vue.$toast.success('Information has been saved');

    onSideMenuTabChange(SideMenuTabsNamesEnum.ThemeImagesTab);
  };

  const onSubmitThemeDetails = async () => {
    const response = await onSaveThemeAsDraft(true);
    if (response instanceof ErrorModel || !response.payload) {
      Vue.$toast.error('Could not save theme details');
      return;
    }

    const submitRes = await submitThemeForReview(themeId);

    if (submitRes instanceof ErrorModel || !submitRes.payload) {
      Vue.$toast.error('Could not submit theme for review');
      return;
    }
    Vue.$toast.success('The theme has been submitted for review');
    router.push({ name: RoutingRouteEnum.ThemesManagement }).catch(() => {
      //
    });
  };

  const areAllFeaturesChecked = computed(() => {
    return standardFeaturesList.list.every((feature) => feature.checked);
  });

  // Theme Details

  // Theme Images
  const getThemeImagesFiles = async () => {
    return Promise.all(
      themeInfoState.themeImages.images.map(async (screen) => {
        if (typeof screen === 'string') {
          const sc = await getFileFromURL(screen, screen, 'image/jpg');
          return sc;
        } else return screen;
      }),
    )
      .then((values) => {
        return values;
      })
      .catch(() => {
        initialThemeImages.list = [];
        return [];
      });
  };

  const onThemeImageChange = async (images: File[]) => {
    const newImages = images.filter(
      (screen) => originalThemeImages.list.findIndex((image) => image.name === screen.name) == -1,
    );

    originalThemeImages.list = [...originalThemeImages.list, ...newImages];

    const areFiles = await Promise.all(
      originalThemeImages.list.map((file) => {
        if (!file.type.includes('image/')) {
          return false;
        }

        const img = new Image();
        img.src = URL.createObjectURL(file);

        return new Promise((resolve) => {
          img.onload = () => {
            resolve(
              (Number((img.width / img.height).toFixed(1)) === DESKTOP_SCREENSHOT_RATIO &&
                img.width <= DESKTOP_SCREENSHOT_MAX_WIDTH &&
                img.width >= DESKTOP_SCREENSHOT_MIN_WIDTH &&
                img.height <= DESKTOP_SCREENSHOT_MAX_HEIGHT &&
                img.height >= DESKTOP_SCREENSHOT_MIN_HEIGHT) ||
                (Number((img.width / img.height).toFixed(2)) === MOBILE_SCREENSHOT_RATIO &&
                  img.width <= MOBILE_SCREENSHOT_MAX_WIDTH &&
                  img.width >= MOBILE_SCREENSHOT_MIN_WIDTH &&
                  img.height <= MOBILE_SCREENSHOT_MAX_HEIGHT &&
                  img.height >= MOBILE_SCREENSHOT_MIN_HEIGHT),
            );
          };
        });
      }),
    );

    if (images.length > MAX_SCREENSHOTS_NUMBER || images.length < MIN_SCREENSHOTS_NUMBER || areFiles.includes(false)) {
      areThemeImagesInvalid.value = true;
    } else {
      areThemeImagesInvalid.value = false;
    }
  };

  const onThemeImageRemove = (image: File) => {
    const screenIndex = originalThemeImages.list.findIndex((screen) => {
      return screen.name === image.name;
    });

    if (screenIndex !== -1) {
      originalThemeImages.list.splice(screenIndex, 1);
      const areFiles = originalThemeImages.list.every((file) => file.size > 0);

      if (
        originalThemeImages.list.length > MAX_SCREENSHOTS_NUMBER ||
        originalThemeImages.list.length < MIN_SCREENSHOTS_NUMBER ||
        !areFiles
      ) {
        areThemeImagesInvalid.value = true;
      } else {
        areThemeImagesInvalid.value = false;
      }
    }
  };

  const onFeaturedImageChange = (image: File) => {
    if (!image) {
      isFeaturedImageInvalid.value = true;
      return;
    }
    v$.value.themeImages.$touch();
    themeInfoState.themeImages.featuredImage = image;
    isFeaturedImageInvalid.value = !themeInfoState.themeImages.featuredImage.type.includes('image/');
  };

  const onRemoveFeaturedImage = () => {
    themeInfoState.themeImages.featuredImage = null;
    isFeaturedImageInvalid.value = true;
  };

  const onAcceptTermsChange = (value: boolean) => {
    v$.value.themeImages.acceptTerms.$touch();
    themeInfoState.themeImages.acceptTerms = value;
  };

  const onSaveThemeAsDraft = async (isSubmitBased = false): Promise<ThemeDetailResponseModel | ErrorModel> => {
    const themeDetails: ThemeDraftDataInterface = {
      price: themeInfoState.themeDetails.price,
      discounted_price: themeInfoState.themeDetails.discountedPrice || '0',
      preview_url: themeInfoState.themeDetails.previewUrl,
      demo_url: themeInfoState.themeDetails.demoStoreUrl,
      description_en: themeInfoState.themeDetails.descriptionEn,
      description_ar: themeInfoState.themeDetails.descriptionAr,
      features: themeInfoState.themeDetails.features,
      additional_features: additionalFeaturesList.list,
      technical_support_email: themeInfoState.themeDetails.technicalSupportEmail,
      website_url: themeInfoState.themeDetails.websiteLink,
      featured_image:
        themeInfoState.themeImages.featuredImage instanceof File ? themeInfoState.themeImages.featuredImage : null,
      category:
        themeInfoState.themeDetails.category
          ?.map((c) => {
            return c.id;
          })
          .join(',') ?? '',
      images: [...originalThemeImages.list],
      theme_file: themeInfoState.themeFile instanceof File ? themeInfoState.themeFile : null,
    };

    const response = await updateThemeInformation(themeId, themeDetails);

    if (response instanceof ErrorModel || !response.payload) {
      if (!isSubmitBased) {
        if (typeof response.message != 'string' && response?.message?.description)
          Vue.$toast.error(response?.message?.description);
        else Vue.$toast.error('Could not save information');
      }
      return response;
    }
    if (!isSubmitBased) Vue.$toast.success('Information has been saved');

    return response;
  };

  const onSaveThemeImageDetailsChanges = async (
    isSubmitBased = false,
  ): Promise<ThemeDetailResponseModel | ErrorModel> => {
    const themeDetails: ThemeDraftDataInterface = {
      images: [...originalThemeImages.list],
      featured_image:
        themeInfoState.themeImages.featuredImage instanceof File ? themeInfoState.themeImages.featuredImage : null,
    };

    const response = await updateThemeInformation(themeId, themeDetails);

    if (response instanceof ErrorModel || !response.payload) {
      if (!isSubmitBased) {
        if (typeof response.message != 'string' && response?.message?.description)
          Vue.$toast.error(response?.message?.description);
        else Vue.$toast.error('Could not save images information');
      }
      return response;
    }
    if (!isSubmitBased) {
      Vue.$toast.success('Images information has been saved');
      onSideMenuTabChange(SideMenuTabsNamesEnum.UpdateThemeTab);
    }
    return response;
  };

  const onSubmitNewThemeToPublish = async () => {
    if (!hasSignedPartnershipForm.value) {
      showPartnershipMessage.value = true;
      return;
    }
    isConfirmSubmitModalVisible.value = true;
  };

  const hideConfirmSubmitModal = () => (isConfirmSubmitModalVisible.value = false);

  const onConfirmSubmitTheme = async () => {
    isLoadingSubmitTheme.value = true;

    const response = await onSaveThemeAsDraft(true);
    if (response instanceof ErrorModel || !response.payload) {
      if (typeof response.message != 'string' && response?.message?.description)
        Vue.$toast.error(response?.message?.description);
      else Vue.$toast.error('Could not save theme details');
      isLoadingSubmitTheme.value = false;
      return;
    }

    const submitRes = await submitThemeForReview(themeId);

    if (submitRes instanceof ErrorModel || !submitRes.payload) {
      if (typeof response.message != 'string' && response?.message?.description)
        Vue.$toast.error(response?.message?.description);
      else Vue.$toast.error('Could not submit theme for review');
      isLoadingSubmitTheme.value = false;
      return;
    }
    Vue.$toast.success('The theme has been submitted for review');
    isLoadingSubmitTheme.value = false;
    router.push({ name: RoutingRouteEnum.ThemesManagement }).catch(() => {
      //
    });
  };

  const onSubmitThemeImages = async () => {
    const response = await onSaveThemeImageDetailsChanges(true);
    if (response instanceof ErrorModel || !response.payload) {
      if (typeof response.message != 'string' && response?.message?.description)
        Vue.$toast.error(response?.message?.description);
      else Vue.$toast.error('Could not save theme images');
      return;
    }

    const submitRes = await submitThemeForReview(themeId);

    if (submitRes instanceof ErrorModel || !submitRes.payload) {
      if (typeof response.message != 'string' && response?.message?.description)
        Vue.$toast.error(response?.message?.description);
      else Vue.$toast.error('Could not submit theme for review');
      return;
    }
    Vue.$toast.success('Theme has been submitted for review');
    router.push({ name: RoutingRouteEnum.ThemesManagement }).catch(() => {
      //
    });
  };
  // Theme Images

  // Create theme
  const isCreateInformationValid = computed(
    () =>
      (!isZipFileInvalid.value || isPreviousFileSaved.value) &&
      (!v$.value.themeFile.$invalid || isPreviousFileSaved.value) &&
      !v$.value.themeDetails.$invalid &&
      !areThemeImagesInvalid.value &&
      !isFeaturedImageInvalid.value &&
      themeInfoState.themeImages.acceptTerms === true,
  );
  // /Create theme

  return {
    /////////////////////
    //// Properties /////
    /////////////////////
    themeInfoState,
    v$,
    sideMenuTabs,
    isCreateFlowType,
    showPartnershipMessage,
    SideMenuTabsNamesEnum,
    PublishingStatusLabelEnum,
    ThemeFlowEnum,
    ItemStatusEnum,
    ItemUpdateStatusEnum,
    isCreateInformationValid,
    isLoadingInformation,
    hasSignedPartnershipForm,
    isLoadingPage,
    isPreviousFileSaved,
    // Theme File
    isZipFileInvalid,
    // Theme Details
    isDiscountEnabled,
    originalThemeCategories,
    areAllFeaturesChecked,
    standardFeaturesList,
    additionalFeaturesList,
    // Theme Images
    initialThemeImages,
    areThemeImagesInvalid,
    isFeaturedImageInvalid,
    isConfirmSubmitModalVisible,
    isLoadingSubmitTheme,
    initialThemeFeaturedImage,
    areInitialThemeImagesLoading,
    isInitialFeaturedImageLoading,
    /////////////////////
    ////// Methods //////
    /////////////////////
    togglePartnershipModal,
    onSideMenuTabChange,
    // Theme File
    onThemeFileChange,
    onRemoveThemeFileClicked,
    // Theme Details
    onThemeDetailsInputChange,
    onDiscountedPriceCheckboxClicked,
    onSaveNewThemeDetailsChanges,
    onSubmitThemeDetails,
    onSelectAllFeatures,
    onSelectFeature,
    onAddAdditionalFeature,
    onDeleteAdditionalFeature,
    onUpdateAdditionalFeatureInput,
    // Theme Images
    onThemeImageChange,
    onThemeImageRemove,
    onFeaturedImageChange,
    onRemoveFeaturedImage,
    onAcceptTermsChange,
    onSaveThemeImageDetailsChanges,
    onSaveThemeAsDraft,
    onSubmitNewThemeToPublish,
    hideConfirmSubmitModal,
    onConfirmSubmitTheme,
    onSubmitThemeImages,
  };
};

export default useThemeInfoContainer;
