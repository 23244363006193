import { computed, defineComponent, ref } from '@vue/composition-api';
import Multiselect from 'vue-multiselect';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import {
  ZidIcon,
  ZidInput,
  ZidLoader,
  ZidButton,
  ZidSelect,
  ZidCheckbox,
  ZidTextarea,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidFileSelector,
  ZidModal,
} from '@zidsa/ui';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EnterIcon from '@/assets/icons/applications/EnterIcon.vue';
import useThemeDetailsAndImages from '@/domains/themes/helpers/management/useThemeDetailsAndImages';
import ExclamationIcon from '@/assets/icons/domains/themes/ExclamationIcon.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import { ThemeDetailsInputPropertiesEnum } from '@/domains/themes/types/management/theme-info-container/themeInfoContainer';
import { ThemeFlowEnum } from '../../../types/management/themesManagement.type';
import { SideMenuTabsNamesEnum } from '@/domains/themes/types/management/theme-info-container/themeInfoContainer';
import I18nLocaleEnum from '@/helpers/i18n/i18nLocale.enum';

export default defineComponent({
  components: {
    ZidIcon,
    BaseText,
    BaseIcon,
    ZidInput,
    ZidLoader,
    ZidSelect,
    ZidButton,
    EnterIcon,
    SearchIcon,
    ZidCheckbox,
    ZidTextarea,
    Multiselect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidFileSelector,
    ExclamationIcon,
    TabMultiLanguage,
    ZidModal,
  },
  props: [
    'isCreateFlowType',
    'vThemeDetails',
    'originalThemeCategories',
    'isDiscountEnabled',
    'hasSignedPartnershipForm',
    'areAllFeaturesChecked',
    'standardFeaturesList',
    'additionalFeaturesList',
  ],
  emits: [
    'onThemeDetailsInputChange',
    'onDiscountedPriceCheckboxClicked',
    'onSideMenuTabChange',
    'onSaveNewThemeDetailsChanges',
    'onSubmitThemeDetails',
    'onSelectAllFeatures',
    'togglePartnershipModal',
    'onSelectFeature',
    'onAddAdditionalFeature',
    'onUpdateAdditionalFeatureInput',
  ],
  setup({ vThemeDetails, hasSignedPartnershipForm }, { emit }) {
    const isConfirmSubmitModalVisible = ref(false);

    const hideConfirmSubmitModal = () => (isConfirmSubmitModalVisible.value = false);

    const onInputDataChange = (changedValue: Record<string, string>, dataProperty: ThemeDetailsInputPropertiesEnum) => {
      emit('onThemeDetailsInputChange', dataProperty, changedValue.value);
    };

    const onDiscountedPriceCheckboxClicked = (value: Record<string, boolean>) => {
      emit('onDiscountedPriceCheckboxClicked', value.isChecked);
    };

    const onSelectFeature = (value: boolean, featureID: number): void => {
      emit('onSelectFeature', value, featureID);
    };

    const onAddAdditionalFeature = () => {
      emit('onAddAdditionalFeature');
    };

    const onUpdateAdditionalFeatureInput = (
      changedValue: Record<string, string>,
      index: number,
      locale: I18nLocaleEnum,
    ): void => {
      emit('onUpdateAdditionalFeatureInput', changedValue.value, index, locale);
    };

    const onDeleteAdditionalFeature = (index: number): void => {
      emit('onDeleteAdditionalFeature', index);
    };

    const onNextClicked = () => emit('onSideMenuTabChange', SideMenuTabsNamesEnum.ThemeImagesTab);

    const onPreviousClicked = () => emit('onSideMenuTabChange', SideMenuTabsNamesEnum.ThemeFileTab);

    const onSaveNewThemeDetailsChanges = () => emit('onSaveNewThemeDetailsChanges');

    const selectAllFeatures = (isChecked: boolean) => {
      emit('onSelectAllFeatures', isChecked);
    };

    const onSubmitThemeDetails = () => {
      if (!hasSignedPartnershipForm) {
        emit('togglePartnershipModal');
        return;
      }
      isConfirmSubmitModalVisible.value = true;
    };

    const onConfirmSubmit = () => {
      emit('onSubmitThemeDetails');
      isConfirmSubmitModalVisible.value = false;
    };

    const showMessageForDescriptionArEnField = computed(() => {
      return (
        (vThemeDetails.descriptionEn.$dirty || vThemeDetails.descriptionAr.$dirty) &&
        (vThemeDetails.descriptionAr.$invalid || vThemeDetails.descriptionEn.$invalid)
      );
    });

    const showMessageForFeature = computed(() => {
      return vThemeDetails.features.$dirty && vThemeDetails.features.$invalid;
    });

    return {
      ThemeFlowEnum,
      showMessageForFeature,
      showMessageForDescriptionArEnField,
      isConfirmSubmitModalVisible,
      I18nLocaleEnum,
      ...useThemeDetailsAndImages(),
      onInputDataChange,
      onDiscountedPriceCheckboxClicked,
      selectAllFeatures,
      onPreviousClicked,
      onNextClicked,
      onSaveNewThemeDetailsChanges,
      onSubmitThemeDetails,
      hideConfirmSubmitModal,
      onConfirmSubmit,
      onAddAdditionalFeature,
      onSelectFeature,
      onDeleteAdditionalFeature,
      onUpdateAdditionalFeatureInput,
    };
  },
});
