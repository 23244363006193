import Vue from 'vue';
import router from '@/router';
import ErrorModel from '@/api/models/ErrorModel';
import {
  ThemeDraftDataInterface,
  UpdateThemeVersionStateInterface,
  VersionTypeEnum,
} from '@/domains/themes/types/management/theme-info-container/themeInfoContainer';
import I18nLocaleEnum from '@/helpers/i18n/i18nLocale.enum';
import {
  ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
  ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS,
} from '@/helpers/regex/text';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { ref, reactive, computed, Ref } from '@vue/composition-api';
import { submitThemeForReview } from '../../api/management/submitThemeForReview';
import { updateThemeInformation } from '../../api/management/themeInfo';

const useUpdateThemeVersion = (
  themeId: string,
  version: string,
  isConfirmUpdateModalVisible: Ref,
  isPreviousFileSaved: boolean,
  notes_ar: string,
  notes_en: string,
) => {
  const MAJOR_VERSION = Number(version.split('.')[0]);
  const MINOR_VERSION = Number(version.split('.')[1]);
  const PATCH_VERSION = Number(version.split('.')[2]);

  const isZipFileInvalid = ref(!isPreviousFileSaved);
  const isLoadingUpdateTheme = ref(false);
  const isConfirmSubmitAllDraftModalVisible = ref(false);

  const updateThemeVersionState = reactive<UpdateThemeVersionStateInterface>({
    selectedVersionType: VersionTypeEnum.Major,
    version: {
      major: MAJOR_VERSION + 1,
      minor: MINOR_VERSION,
      patch: PATCH_VERSION,
    },
    themeFile: null,
    changeNotes: {
      ar: notes_ar || '',
      en: notes_en || '',
    },
  });

  const hideConfirmSubmitDraftModal = () => (isConfirmSubmitAllDraftModalVisible.value = false);

  const onVersionTypeChange = (versionType: VersionTypeEnum) => {
    updateThemeVersionState.selectedVersionType = versionType;
    if (versionType == VersionTypeEnum.Major) {
      updateThemeVersionState.version.major = MAJOR_VERSION + 1;
      updateThemeVersionState.version.minor = MINOR_VERSION;
      updateThemeVersionState.version.patch = PATCH_VERSION;
    } else if (versionType == VersionTypeEnum.Minor) {
      updateThemeVersionState.version.major = MAJOR_VERSION;
      updateThemeVersionState.version.minor = MINOR_VERSION + 1;
      updateThemeVersionState.version.patch = PATCH_VERSION;
    } else {
      updateThemeVersionState.version.major = MAJOR_VERSION;
      updateThemeVersionState.version.minor = MINOR_VERSION;
      updateThemeVersionState.version.patch = PATCH_VERSION + 1;
    }
  };

  const onNotesChange = (changedValue: Record<string, string>, lang: I18nLocaleEnum) => {
    if (lang === I18nLocaleEnum.en) {
      updateThemeVersionState.changeNotes.en = changedValue.value;
    } else {
      updateThemeVersionState.changeNotes.ar = changedValue.value;
    }
  };

  const onThemeFileChange = (file: File[]) => {
    if (!file) return;
    updateThemeVersionState.themeFile = file[0];
    isZipFileInvalid.value =
      !file[0] ||
      file[0].size > 5000000 ||
      (file[0].type != 'application/zip' && file[0].type != 'application/x-zip-compressed');
  };

  const onRemoveThemeFileClicked = () => {
    updateThemeVersionState.themeFile = null;
    isZipFileInvalid.value = true;
  };

  const hideConfirmUpdateModal = () => (isConfirmUpdateModalVisible.value = false);

  const saveDraftVersion = async () => await onSaveDraftThemeVersion(false);

  const onSaveDraftThemeVersion = async (isSubmitBased = false) => {
    const themeUpdateDetails: ThemeDraftDataInterface = {
      change_type: updateThemeVersionState.selectedVersionType,
      theme_file: updateThemeVersionState.themeFile instanceof File ? updateThemeVersionState.themeFile : null,
      changes_notes_en: updateThemeVersionState.changeNotes.en,
      changes_notes_ar: updateThemeVersionState.changeNotes.ar,
    };

    const response = await updateThemeInformation(themeId, themeUpdateDetails);

    if (response instanceof ErrorModel || !response.payload) {
      if (!isSubmitBased) {
        if (typeof response.message != 'string' && response?.message?.description)
          Vue.$toast.error(response?.message?.description);
        else Vue.$toast.error('Could not save theme version information');
      }
      return response;
    }
    if (!isSubmitBased) Vue.$toast.success('Theme version information has been saved');

    return response;
  };

  const onConfirmUpdate = async () => {
    isLoadingUpdateTheme.value = true;
    const response = await onSaveDraftThemeVersion(true);
    if (response instanceof ErrorModel || !response.payload) {
      if (typeof response.message != 'string' && response?.message?.description)
        Vue.$toast.error(response?.message?.description);
      else Vue.$toast.error('Could not save theme version details');
      isLoadingUpdateTheme.value = false;
      return;
    }

    const submitRes = await submitThemeForReview(themeId);

    if (submitRes instanceof ErrorModel || !submitRes.payload) {
      if (typeof response.message != 'string' && response?.message?.description)
        Vue.$toast.error(response?.message?.description);
      else Vue.$toast.error('Could not submit theme for review');
      isLoadingUpdateTheme.value = false;
      return;
    }
    Vue.$toast.success('Theme has been submitted for review');
    isLoadingUpdateTheme.value = false;
    isConfirmUpdateModalVisible.value = false;
    router.push({ name: RoutingRouteEnum.ThemesManagement }).catch(() => {
      //
    });
  };

  const onSubmitAllDraftInformation = () => {
    isConfirmSubmitAllDraftModalVisible.value = true;
  };

  const onConfirmSubmitAllDraft = async () => {
    isLoadingUpdateTheme.value = true;

    const submitRes = await submitThemeForReview(themeId);

    if (submitRes instanceof ErrorModel || !submitRes.payload) {
      Vue.$toast.error('Could not submit theme for review');
      isLoadingUpdateTheme.value = false;
      return;
    }
    Vue.$toast.success('Theme has been submitted for review');
    isLoadingUpdateTheme.value = false;
    isConfirmUpdateModalVisible.value = false;
    router.push({ name: RoutingRouteEnum.ThemesManagement }).catch(() => {
      //
    });
  };
  const changeNotesArInvalid = computed(() => {
    return (
      updateThemeVersionState.changeNotes.ar != '' &&
      !ARABIC_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(updateThemeVersionState.changeNotes.ar)
    );
  });

  const changeNotesEnInvalid = computed(() => {
    return (
      updateThemeVersionState.changeNotes.en != '' &&
      !ENGLISH_TEXT_WITH_NEW_LINE_AND_SPECIAL_CHARS.test(updateThemeVersionState.changeNotes.en)
    );
  });

  const areVersionUpdateInfoInvalid = computed(() => {
    return (
      isZipFileInvalid.value ||
      changeNotesArInvalid.value ||
      changeNotesEnInvalid.value ||
      updateThemeVersionState.changeNotes.ar == '' ||
      updateThemeVersionState.changeNotes.en == ''
    );
  });

  return {
    VersionTypeEnum,
    updateThemeVersionState,
    isZipFileInvalid,
    I18nLocaleEnum,
    changeNotesArInvalid,
    changeNotesEnInvalid,
    areVersionUpdateInfoInvalid,
    isConfirmUpdateModalVisible,
    isLoadingUpdateTheme,
    isConfirmSubmitAllDraftModalVisible,
    onVersionTypeChange,
    onThemeFileChange,
    onRemoveThemeFileClicked,
    onNotesChange,
    hideConfirmUpdateModal,
    onConfirmUpdate,
    onSaveDraftThemeVersion,
    saveDraftVersion,
    hideConfirmSubmitDraftModal,
    onSubmitAllDraftInformation,
    onConfirmSubmitAllDraft,
  };
};

export default useUpdateThemeVersion;
