export interface SubmitThemeFormStateInterface {
  nameEn: string;
  nameAr: string;
  price: string;
  discountedPrice: string;
  category:
    | {
        id: number;
        name: string;
      }[]
    | null;
  previewUrl: string;
  demoStoreUrl: string;
  descriptionEn: string;
  descriptionAr: string;
  featuresEn: string;
  featuresAr: string;
  technicalSupportEmail: string;
  websiteLink: string;

  themeFile: string | File | null;
  updateType: {
    label: string;
    value: string;
  };
  notes: string;
  images: (string | File)[];
  featuredImage: File | null;
  acceptTerms: boolean;
}

export enum SubmitThemeSelectPropertyEnum {
  UpdateType = 'updateType',
}

export type SubmitThemeParamsType = {
  name_en: string;
  name_ar: string;
  price: string;
  discountedPrice: string;
  category: (number | null)[];
  previewUrl: string;
  demoStoreUrl: string;
  description_en: string;
  description_ar: string;
  features_en: string;
  features_ar: string;
  technicalSupportEmail: string;
  websiteUrl: string;
  images: (string | File)[];
  featuredImage: File | null;
};

export type UpdateThemeParamsType = {
  name_en: string;
  name_ar: string;
  price: string;
  discountedPrice: string;
  category: (number | null)[];
  previewUrl: string;
  demoStoreUrl: string;
  description_en: string;
  description_ar: string;
  features_en: string;
  features_ar: string;
  technicalSupportEmail: string;
  websiteUrl: string;
  images: (string | File)[];
  featuredImage: File | null;
  themeFile: string | File | null;
  updateType: string;
  notes: string;
};
