import { defineComponent, ref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import { ZidIcon, ZidInput, ZidModal, ZidButton, ZidTextarea, ZidFileSelector, ZidRadio } from '@zidsa/ui';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import useUpdateThemeVersion from '../../../helpers/management/useUpdateThemeVersion';
import { SideMenuTabsNamesEnum } from '@/domains/themes/types/management/theme-info-container/themeInfoContainer';
import ExclamationIcon from '@/assets/icons/domains/themes/ExclamationIcon.vue';

export default defineComponent({
  components: {
    ZidIcon,
    BaseText,
    BaseIcon,
    ZidModal,
    ZidInput,
    ZidButton,
    ZidTextarea,
    ZidFileSelector,
    TabMultiLanguage,
    ZidRadio,
    ExclamationIcon,
  },
  props: ['version', 'vThemeFile', 'notes_ar', 'notes_en', 'isPreviousFileSaved', 'hasSignedPartnershipForm'],
  emits: ['onSideMenuTabChange', 'togglePartnershipModal'],
  setup({ version, hasSignedPartnershipForm, isPreviousFileSaved, notes_ar, notes_en }, { root, emit }) {
    const isConfirmUpdateModalVisible = ref(false);

    const { themeId } = root.$route.params;

    const onPreviousClicked = () => emit('onSideMenuTabChange', SideMenuTabsNamesEnum.ThemeImagesTab);

    const onSubmitThemeVersionUpdate = () => {
      if (!hasSignedPartnershipForm) {
        emit('togglePartnershipModal');
        return;
      }
      isConfirmUpdateModalVisible.value = true;
    };

    return {
      ...useUpdateThemeVersion(themeId, version, isConfirmUpdateModalVisible, isPreviousFileSaved, notes_ar, notes_en),
      onPreviousClicked,
      onSubmitThemeVersionUpdate,
    };
  },
});
