import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import ThemeDetailResponseModel from '../../models/management/ThemeDetailResponseModel';

const ENDPOINT_URL = '/pd/themes/submit';

export const submitThemeForReview = (themeId: string): Promise<ThemeDetailResponseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/${themeId}`)
    .then((response) => new ThemeDetailResponseModel(response))
    .catch((error) => error);
};
