import { defineComponent, ref } from '@vue/composition-api';
import Multiselect from 'vue-multiselect';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import {
  ZidIcon,
  ZidInput,
  ZidModal,
  ZidLoader,
  ZidButton,
  ZidSelect,
  ZidCheckbox,
  ZidTextarea,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidFileSelector,
} from '@zidsa/ui';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EnterIcon from '@/assets/icons/applications/EnterIcon.vue';
import ExclamationIcon from '@/assets/icons/domains/themes/ExclamationIcon.vue';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import { SideMenuTabsNamesEnum } from '@/domains/themes/types/management/theme-info-container/themeInfoContainer';
import { ThemeFlowEnum } from '../../../types/management/themesManagement.type';
import useThemeDetailsAndImages from '@/domains/themes/helpers/management/useThemeDetailsAndImages';

export default defineComponent({
  components: {
    ZidIcon,
    BaseText,
    BaseIcon,
    ZidModal,
    ZidInput,
    ZidLoader,
    ZidSelect,
    ZidButton,
    EnterIcon,
    SearchIcon,
    ZidCheckbox,
    ZidTextarea,
    Multiselect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidFileSelector,
    ExclamationIcon,
    TabMultiLanguage,
  },
  props: [
    'isCreateFlowType',
    'vThemeImages',
    'isCreateInformationValid',
    'areThemeImagesInvalid',
    'initialThemeImages',
    'isFeaturedImageInvalid',
    'haveAcceptedTerms',
    'isPreviousFileSaved',
    'initialThemeFeaturedImage',
    'areInitialThemeImagesLoading',
    'isInitialFeaturedImageLoading',
    'hasSignedPartnershipForm',
  ],
  emits: [
    'onThemeImageChange',
    'onThemeImageRemove',
    'onFeaturedImageChange',
    'onRemoveFeaturedImage',
    'onAcceptTermsChange',
    'onSideMenuTabChange',
    'onSaveThemeImageDetailsChanges',
    'onSaveThemeAsDraft',
    'onSubmitNewThemeToPublish',
    'onSubmitThemeImages',
    'togglePartnershipModal',
  ],
  setup({ hasSignedPartnershipForm }, { emit }) {
    const isConfirmSubmitModalVisible = ref(false);

    const hideConfirmSubmitModal = () => (isConfirmSubmitModalVisible.value = false);

    const onThemeImageChange = (images: File[]) => emit('onThemeImageChange', images);

    const onThemeImageRemove = (image: File) => emit('onThemeImageRemove', image);

    const onFeaturedImageChange = (image: File[]) => {
      if (!image) return;
      emit('onFeaturedImageChange', image[0]);
    };

    const onRemoveFeaturedImage = () => emit('onRemoveFeaturedImage');

    const onAcceptTermsChange = (value: Record<string, boolean>) => emit('onAcceptTermsChange', value.isChecked);

    const onPreviousClicked = () => emit('onSideMenuTabChange', SideMenuTabsNamesEnum.ThemeDetailsTab);

    const onNextClicked = () => emit('onSideMenuTabChange', SideMenuTabsNamesEnum.UpdateThemeTab);

    const onSaveThemeImageDetailsChanges = () => emit('onSaveThemeImageDetailsChanges');

    const onSaveDraft = () => emit('onSaveThemeAsDraft');

    const onSubmitToPublish = () => emit('onSubmitNewThemeToPublish');

    const onSubmitThemeImages = () => {
      if (!hasSignedPartnershipForm) {
        emit('togglePartnershipModal');
        return;
      }

      isConfirmSubmitModalVisible.value = true;
    };

    const onConfirmSubmitThemeImages = () => {
      emit('onSubmitThemeImages');
      isConfirmSubmitModalVisible.value = false;
    };

    return {
      ThemeFlowEnum,
      isConfirmSubmitModalVisible,
      ...useThemeDetailsAndImages(),
      onThemeImageChange,
      onThemeImageRemove,
      onFeaturedImageChange,
      onRemoveFeaturedImage,
      onAcceptTermsChange,
      onPreviousClicked,
      onSaveThemeImageDetailsChanges,
      onSaveDraft,
      onSubmitToPublish,
      onNextClicked,
      onSubmitThemeImages,
      hideConfirmSubmitModal,
      onConfirmSubmitThemeImages,
    };
  },
});
