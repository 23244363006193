export const getFileFromURL = async (url: string, name: string, defaultType: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: response.headers.get('content-type') || defaultType,
  });
};

export const getImageFileFromURL = async (url: string, name: string, defaultType: string) => {
  try {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get('content-type') || defaultType,
    });
  } catch (error) {
    return null;
  }
};
