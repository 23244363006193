var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.sideMenuTabs),function(value,tab){return _c('div',{key:tab},[(value != null)?_c('div',{class:{
        'p-4 mb-4 flex items-center rounded cursor-pointer': true,
        'bg-neutrals-50': value,
      },attrs:{"id":("theme-info-container_data_section_side_menu_" + tab)},on:{"click":function($event){_vm.onTabChange(_vm.SideMenuTabsNamesEnum[("" + (tab.charAt(0).toUpperCase()) + (tab.slice(1)))])}}},[_c('div',{class:{
          'rounded me-3': true,
          'bg-primary-100': value,
          'bg-neutrals-50 ': !value,
        }},[_c('base-icon',{class:{ 'm-3': true, 'text-primary-600': value },attrs:{"width":"18","height":"18","viewBox":"0 0 18 18"}},[(tab === _vm.SideMenuTabsNamesEnum.ThemeFileTab)?_c('add-file-icon'):(tab === _vm.SideMenuTabsNamesEnum.ThemeDetailsTab)?_c('edit-file-icon'):(tab === _vm.SideMenuTabsNamesEnum.ThemeImagesTab)?_c('edit-image-icon'):_c('paint-icon')],1)],1),_c('div',{staticClass:"flex flex-col"},[_c('base-text',{attrs:{"textStyle":"p3","classes":("me-4 " + (value ? 'text-primary-600' : 'text-neutrals-black'))}},[_vm._v(" "+_vm._s(tab === _vm.SideMenuTabsNamesEnum.ThemeFileTab ? 'Theme ZIP File' : tab === _vm.SideMenuTabsNamesEnum.ThemeDetailsTab ? 'Theme Details' : tab === _vm.SideMenuTabsNamesEnum.ThemeImagesTab ? 'Theme Images' : tab === _vm.SideMenuTabsNamesEnum.UpdateThemeTab ? 'Update Theme' : '')+" ")])],1)]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }