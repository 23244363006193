import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import StandardThemeFeaturesModel from '../../models/management/StandardThemeFeaturesModel';
import ThemeDetailResponseModel from '../../models/management/ThemeDetailResponseModel';
import { ThemeDraftDataInterface } from '../../types/management/theme-info-container/themeInfoContainer';

const ENDPOINT_URL = '/pd/themes';

export const getThemeInformation = (themeID: string): Promise<ThemeDetailResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${themeID}`)
    .then((response) => new ThemeDetailResponseModel(response))
    .catch((error) => error);
};

export const updateThemeInformation = (
  themeID: string,
  themeDetails: ThemeDraftDataInterface,
): Promise<ThemeDetailResponseModel | ErrorModel> => {
  const themeData = new FormData();

  if (themeDetails.price) themeData.append('price', themeDetails.price);
  if (themeDetails.theme_file) themeData.append('theme_file', themeDetails.theme_file);
  if (themeDetails.discounted_price) themeData.append('discounted_price', themeDetails.discounted_price);
  if (themeDetails.category) themeData.append('category', themeDetails.category);
  if (themeDetails.preview_url) themeData.append('preview_url', themeDetails.preview_url);
  if (themeDetails.demo_url) themeData.append('demo_url', themeDetails.demo_url);
  if (themeDetails.website_url) themeData.append('website_url', themeDetails.website_url);
  if (themeDetails.technical_support_email)
    themeData.append('technical_support_email', themeDetails.technical_support_email);
  if (themeDetails.description_ar) themeData.append('description_ar', themeDetails.description_ar);
  if (themeDetails.description_en) themeData.append('description_en', themeDetails.description_en);
  if (themeDetails.features?.length) {
    themeDetails.features.forEach((feature, index) => {
      themeData.append(`features[${index}]`, `${feature}`);
    });
  }
  if (themeDetails.additional_features?.length) {
    themeDetails.additional_features.forEach((feature, index) => {
      for (const [key, value] of Object.entries(feature)) {
        themeData.append(`additional_features[${index}][${key}]`, value);
      }
    });
  }
  if (themeDetails.featured_image) themeData.append('featured_image', themeDetails.featured_image);
  if (themeDetails.change_type) themeData.append('change_type', themeDetails.change_type);
  if (themeDetails.changes_notes_en) themeData.append('changes_notes_en', themeDetails.changes_notes_en);
  if (themeDetails.changes_notes_ar) themeData.append('changes_notes_ar', themeDetails.changes_notes_ar);
  if (themeDetails.images?.length)
    themeDetails.images.map((image, index) => {
      themeData.append(`images[${index}]`, image);
    });

  return httpClient
    .post(`${ENDPOINT_URL}/update-details/${themeID}`, themeData)
    .then((response) => new ThemeDetailResponseModel(response))
    .catch((error) => error);
};

export const getStandardThemeFeatures = (): Promise<StandardThemeFeaturesModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/features`)
    .then((response) => new StandardThemeFeaturesModel(response))
    .catch((error) => error);
};
