import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import ThemeCategoriesResponseModel from '@/domains/themes/models/management/ThemeCategoriesResponseModel';

const ENDPOINT_URL = '/pd/themes/industries';

export const getThemeCategories = (): Promise<ThemeCategoriesResponseModel | ErrorModel> => {
  return httpClient
    .get(ENDPOINT_URL)
    .then((response) => new ThemeCategoriesResponseModel(response))
    .catch((error) => error);
};
