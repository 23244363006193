import BaseModel from '@/api/models/BaseModel';
import { SingleIndustryType } from '../../types/management/theme-info-container/themeInfoContainer';

class ThemeCategoriesResponseModel extends BaseModel {
  public industries: SingleIndustryType[];

  constructor(arg: any) {
    super(arg);
    this.industries = arg.industries;
  }
}

export default ThemeCategoriesResponseModel;
