import { defineComponent } from '@vue/composition-api';
import {
  ZidIcon,
  ZidModal,
  ZidLoader,
  ZidButton,
  ZidPageHeader,
  ZidPagination,
  ZidCard,
  ZidCardHeader,
  ZidCardBody,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EditIcon from '@/assets/icons/domains/themes/EditIcon.vue';
import RightPointingArrowIcon from '@/assets/icons/domains/team-members/RightPointingArrowIcon.vue';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BadgeIcon from '@/assets/icons/domains/themes/BadgeIcon.vue';
import { ThemeActionsEnum, ThemeFlowEnum } from '../../../types/management/themesManagement.type';
import useThemeInfoContainer from '../../../helpers/management/theme-info-container/useThemeInfoContainer';
import ThemeInfoSideMenu from '../../../components/management/side-menu/ThemeInfoSideMenu.vue';
import ThemeFile from '../upload/ThemeFile.vue';
import ThemeDetails from '../details/ThemeDetails.vue';
import ThemeImages from '../images/ThemeImages.vue';
import UpdateThemeVersion from '../update-version/UpdateThemeVersion.vue';
import ExclamationWarnIcon from '@/assets/icons/domains/team-members/ExclamationIcon.vue';
import ExclamationIcon from '@/assets/icons/domains/themes/ExclamationIcon.vue';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidIcon,
    SearchIcon,
    EditIcon,
    ZidModal,
    ZidLoader,
    ZidButton,
    ZidPageHeader,
    RightPointingArrowIcon,
    ZidPagination,
    ZidCard,
    ZidCardHeader,
    ZidCardBody,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    BadgeIcon,
    ThemeInfoSideMenu,
    ThemeFile,
    ThemeDetails,
    ThemeImages,
    UpdateThemeVersion,
    ExclamationIcon,
    ExclamationWarnIcon,
  },
  setup(_, { root }) {
    const { themeId, flowType } = root.$route.params;

    return {
      ThemeActionsEnum,
      RoutingRouteEnum,
      ...useThemeInfoContainer(themeId, flowType as ThemeFlowEnum),
      flowType,
    };
  },
});
