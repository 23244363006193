import { ItemStatusEnum, ItemUpdateStatusEnum } from '../themesManagement.type';

export interface ThemeInfoStateInterface {
  themeStatus?: ItemStatusEnum | null;
  themeUpdateStatus?: ItemUpdateStatusEnum | null;
  published_date?: string | null;
  theme_version?: string;
  changes_notes_en?: string;
  changes_notes_ar?: string;
  themeFile: File | null;
  themeDetails: {
    nameEn: string;
    nameAr: string;
    price: string;
    discountedPrice: string;
    category:
      | {
          id: number;
          name: string;
        }[];
    previewUrl: string;
    demoStoreUrl: string;
    descriptionEn: string;
    descriptionAr: string;
    features: number[];
    technicalSupportEmail: string;
    websiteLink: string;
  };
  themeImages: {
    images: (string | File)[];
    featuredImage: File | null;
    acceptTerms: boolean;
  };
}

export interface ThemeFeature {
  id: number;
  title: string;
  required: boolean;
}
export interface StandardThemeFeatureItemInterface {
  id: number;
  title: string;
  checked: boolean;
  required: boolean;
}

export interface AdditionalFeature {
  ar: string;
  en: string;
}

export interface ThemeDraftDataInterface {
  theme_file?: File | null;
  nameEn?: string;
  nameAr?: string;
  price?: string;
  discounted_price?: string;
  category?: string | null;
  preview_url?: string;
  demo_url?: string;
  website_url?: string;
  technical_support_email?: string;
  description_ar?: string;
  description_en?: string;
  features?: number[];
  additional_features?: AdditionalFeature[];
  featured_image?: File | null;
  images?: (string | File)[];
  change_type?: string;
  changes_notes_en?: string;
  changes_notes_ar?: string;
}

export interface SideMenuTabsInterface {
  themeFileTab: null | boolean;
  themeDetailsTab: null | boolean;
  themeImagesTab: null | boolean;
  updateThemeTab: null | boolean;
}

export enum SideMenuTabsNamesEnum {
  ThemeFileTab = 'themeFileTab',
  ThemeDetailsTab = 'themeDetailsTab',
  ThemeImagesTab = 'themeImagesTab',
  UpdateThemeTab = 'updateThemeTab',
}

export enum ThemeDetailsInputPropertiesEnum {
  Price = 'price',
  DiscountedPrice = 'discountedPrice',
  PreviewUrl = 'previewUrl',
  DemoStoreUrl = 'demoStoreUrl',
  DescriptionEn = 'descriptionEn',
  DescriptionAr = 'descriptionAr',
  TechnicalSupportEmail = 'technicalSupportEmail',
  WebsiteLink = 'websiteLink',
}

export type ThemeCategoryType = {
  options: {
    id: number;
    name: string;
  }[];
};

export type SingleIndustryType = {
  id: number;
  name: string;
};

export enum VersionTypeEnum {
  Major = 'major',
  Minor = 'minor',
  Patch = 'patch',
}
export interface UpdateThemeVersionStateInterface {
  selectedVersionType: VersionTypeEnum;
  version: {
    major: number;
    minor: number;
    patch: number;
  };
  themeFile: File | null;
  changeNotes: {
    ar: string;
    en: string;
  };
}
