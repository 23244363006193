import BaseModel from '@/api/models/BaseModel';
import { ThemeFeature } from '../../types/management/theme-info-container/themeInfoContainer';

class StandardThemeFeaturesModel extends BaseModel {
  public features: ThemeFeature[];

  constructor(arg: any) {
    super(arg);
    this.features = arg.features;
  }
}

export default StandardThemeFeaturesModel;
